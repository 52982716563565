import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
// const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const About = React.lazy(() => import("./components/pages/About"));
const Blog = React.lazy(() => import("./components/pages/Blog"));
// const Blogleft = React.lazy(() => import("./components/pages/Blogleft"));
const Blogdetail = React.lazy(() => import("./components/pages/Blogdetail"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Servicedetail = React.lazy(() =>
  import("./components/pages/Servicedetail")
);
const Loancalculator = React.lazy(() =>
  import("./components/pages/Loancalculator")
);
// const Loanstep = React.lazy(() => import("./components/pages/Loanstep"));
// const Team = React.lazy(() => import("./components/pages/Team"));
const Finance = React.lazy(() => import("./components/pages/Finance"));
// const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
// const Userdashboard = React.lazy(() =>
//   import("./components/pages/Userdashboard")
// );

function App() {
  return (
    <div className="main-body">
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route path='/home-v2' component={Hometwo} /> */}
            <Route path="/about" component={About} />
            <Route path="/blog" component={Blog} />
            {/* <Route path='/blog-sidebar' component={Blogleft} /> */}
            <Route path="/blog-single/:id" component={Blogdetail} />
            <Route path="/service" component={Service} />
            <Route path="/service-detail/:id" component={Servicedetail} />
            <Route path="/loan-calculator" component={Loancalculator} />
            {/* <Route path='/loan-step' component={Loanstep} /> */}
            {/* <Route path='/team' component={Team} /> */}
            <Route path="/finance" component={Finance} />
            {/* <Route path='/coming-soon' component={Comingsoon} /> */}
            <Route path="/error" component={Error} />
            <Route path="/contact" component={Contact} />
            {/* <Route path='/loan-dashboard' component={Userdashboard} /> */}
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
